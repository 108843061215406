import { AlertBox, AlertBoxDismissButton, Link, Typography } from '@vp/swan'
import { useUserContext } from '@vp/ubik-context'
import { useLocalization } from '../hooks/useLocalizations'
import useLocalizedLink from '../hooks/useLocalizedLink'
import { DEFAULT_LOCALE, PRIVACY_AND_COOKIE_POLICY_PAGE_ID } from '../utils/constants'

export interface AccountProfileAlertBoxesProps {
  showSaveAlertBox?: boolean
  isSuccessSavingAccountProfile?: boolean
  isErrorSavingAccountProfile?: boolean
  showDeleteAlertBox?: boolean
  isSuccessDeletingAccountProfile?: boolean
  isErrorDeletingAccountProfile?: boolean
  showInvitationAlertBox?: boolean
  isAccountProfileCompleted?: boolean
  onInvitationAlertBoxClicked?: () => void
}

export const AccountProfileAlertBoxes = (props: AccountProfileAlertBoxesProps) => {
  const { locale = DEFAULT_LOCALE, tenant = '' } = useUserContext()
  const { link } = useLocalizedLink({ tenant, locale, page: PRIVACY_AND_COOKIE_POLICY_PAGE_ID })
  const privacyPolicyLink = link ?? ''

  const { t } = useLocalization()
  const {
    showSaveAlertBox,
    isSuccessSavingAccountProfile,
    isErrorSavingAccountProfile,
    showDeleteAlertBox,
    isSuccessDeletingAccountProfile,
    isErrorDeletingAccountProfile,
    showInvitationAlertBox,
    isAccountProfileCompleted,
    onInvitationAlertBoxClicked
  } = props

  const renderInvitationAlertTextDisclaimer = () => {
    const text = t('invitationAlertTextDisclaimer')
    const parts = text.split('{{PRIVACY_COOKIE_POLICY_LINK}}')
    return (
      <>
        {parts[0]}
        <Link href={privacyPolicyLink} target='blank'>
          {t('privacyAndCookiePolicy')}
        </Link>
        {parts[1]}
      </>
    )
  }

  const invitationAlertBoxMarginTop = showSaveAlertBox || showDeleteAlertBox ? '4' : 'between-subsections'

  return (
    <>
      {showSaveAlertBox && isSuccessSavingAccountProfile && (
        <AlertBox skin='positive' mt='between-subsections' data-testid='account-profile-success-saving-alert-box'>
          {t('SuccessSavingAlertText')}
          <AlertBoxDismissButton accessibleText={t('Close')} />
        </AlertBox>
      )}
      {showSaveAlertBox && isErrorSavingAccountProfile && (
        <AlertBox skin='error' mt='between-subsections' data-testid='account-profile-error-saving-alert-box'>
          {t('ErrorSavingAlertText')}
          <AlertBoxDismissButton accessibleText={t('Close')} />
        </AlertBox>
      )}
      {showDeleteAlertBox && isSuccessDeletingAccountProfile && (
        <AlertBox skin='positive' mt='between-subsections' data-testid='account-profile-success-deleting-alert-box'>
          {t('SuccessDeletingAlertText')}
          <AlertBoxDismissButton accessibleText={t('Close')} />
        </AlertBox>
      )}
      {showDeleteAlertBox && isErrorDeletingAccountProfile && (
        <AlertBox skin='error' mt='between-subsections' data-testid='account-profile-error-deleting-alert-box'>
          {t('ErrorDeletingAlertText')}
          <AlertBoxDismissButton accessibleText={t('Close')} />
        </AlertBox>
      )}
      {showInvitationAlertBox && (
        <AlertBox onRequestDismiss={onInvitationAlertBoxClicked} skin='info' mt={invitationAlertBoxMarginTop} data-testid='account-profile-invitation-alert-box'>
          {!isAccountProfileCompleted && (
            <>
              <Typography fontSkin='body-standard' data-testid='account-profile-invitation-alert-text'>{t('invitationAlertText')}</Typography>
              <Typography fontSkin='body-small' textColor='subtle' mt='3' data-testid='account-profile-invitation-alert-text-privacy'>
                {renderInvitationAlertTextDisclaimer()}
              </Typography>
            </>
          )}
          {isAccountProfileCompleted && (
            <>
              <Typography fontSkin='body-standard' data-testid='account-profile-invitation-completed-alert-text'>{t('accountProfileCompletedAlertText')}</Typography>
              <Typography fontSkin='body-small' textColor='subtle' mt='3' data-testid='account-profile-invitation-completed-alert-text-privacy'>
                {renderInvitationAlertTextDisclaimer()}
              </Typography>
            </>
          )}
          {isAccountProfileCompleted && (<AlertBoxDismissButton data-testid='account-profile-invitation-close' accessibleText={t('Close')} />)}
        </AlertBox>
      )}
    </>
  )
}

export default AccountProfileAlertBoxes
