import { FlexBox, Button, ModalDialog, ModalDialogBody, ModalDialogButtons, ModalDialogContent, ModalDialogFooter, ModalDialogHeader, ModalDialogTitle, Spinner } from '@vp/swan'
import { useLocalization } from '../hooks/useLocalizations'
import React from 'react'
import styles from './accountProfile.css'
import { trackCancelAccountProfileClickedEvent, trackCancelAccountProfileConfirmClickedEvent, trackCancelAccountProfileDiscardClickedEvent, trackDeleteAccountProfileClickedEvent, trackDeleteAccountProfileConfirmClickedEvent, trackDeleteAccountProfileDiscardClickedEvent } from '../utils/tracking'

export interface AccountProfileFormButtonsProps {
  hasErrors: boolean
  isFormDirty: boolean
  isSaving: boolean
  isDeleting: boolean
  isCanceling: boolean
  onSaveClick: () => void
  onCancelClick: () => void
  onDeleteProfileClick: () => void
}

export const AccountProfileFormButtons = (props: AccountProfileFormButtonsProps) => {
  const [isCancelModalDialogOpen, setIsCancelModalDialogOpen] = React.useState(false)
  const [isDeleteProfileModalDialogOpen, setIsDeleteProfileModalDialogOpen] = React.useState(false)

  const { onSaveClick, onCancelClick, onDeleteProfileClick, hasErrors, isSaving, isDeleting, isCanceling, isFormDirty } = props
  const isPerformingAction = isSaving || isDeleting || isCanceling

  const { t } = useLocalization()

  const handleCancelClick = () => {
    setIsCancelModalDialogOpen(true)
    trackCancelAccountProfileClickedEvent()
  }

  const handleCancelConfirmClick = () => {
    setIsCancelModalDialogOpen(false)
    trackCancelAccountProfileConfirmClickedEvent()
    onCancelClick()
  }

  const handleCancelDiscardClick = () => {
    setIsCancelModalDialogOpen(false)
    trackCancelAccountProfileDiscardClickedEvent()
  }

  const handleDeleteProfileClick = () => {
    setIsDeleteProfileModalDialogOpen(true)
    trackDeleteAccountProfileClickedEvent()
  }

  const handleDeleteProfileConfirmClick = () => {
    setIsDeleteProfileModalDialogOpen(false)
    trackDeleteAccountProfileConfirmClickedEvent()
    onDeleteProfileClick()
  }
  const handleDeleteProfileDiscardClick = () => {
    setIsDeleteProfileModalDialogOpen(false)
    trackDeleteAccountProfileDiscardClickedEvent()
  }

  return (
    <FlexBox mt='between-subsections' className={styles.accountProfileButtonsWrapper}>
      <Button
        className={styles.accountProfileButton}
        iconPosition='right'
        disabled={!isFormDirty || isPerformingAction || hasErrors}
        data-testid='account-profile-save-button'
        skin='primary'
        mr='between-actions'
        mb='0'
        onClick={() => onSaveClick()}
      >
        {isSaving && <Spinner size='tiny' accessibleText={t('Loading')} data-testid='account-profile-save-button-spinner' />}
        {t('buttonSave')}
      </Button>

      <Button
        className={styles.accountProfileButton}
        iconPosition='right'
        disabled={!isFormDirty || isPerformingAction}
        data-testid='account-profile-cancel-button'
        skin='secondary'
        mr='between-actions'
        mb='0'
        onClick={handleCancelClick}
      >
        {isCanceling && <Spinner size='tiny' accessibleText={t('Loading')} data-testid='account-profile-cancel-button-spinner' />}
        {t('buttonCancel')}
      </Button>

      <ModalDialog isOpen={isCancelModalDialogOpen}>
        <ModalDialogContent aria-labelledby='modal dialog button'>
          <ModalDialogHeader>
            <ModalDialogTitle data-testid='cancel-modal-dialog-title'>{t('CancelModalDialogTitle')}</ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
            {t('CancelModalDialogDescription')}
          </ModalDialogBody>
          <ModalDialogFooter>
            <ModalDialogButtons>
              <Button
                className={styles.accountProfileButton}
                data-testid='cancel-modal-dialog-primary-button'
                skin='primary' onClick={handleCancelConfirmClick}
              >
                {t('CancelModalDialogPrimaryButton')}
              </Button>
              <Button
                className={styles.accountProfileButton}
                data-testid='cancel-modal-dialog-secondary-button'
                skin='secondary' onClick={handleCancelDiscardClick}
              >{t('CancelModalDialogSecondaryButton')}
              </Button>
            </ModalDialogButtons>
          </ModalDialogFooter>
        </ModalDialogContent>
      </ModalDialog>

      <Button
        className={styles.accountProfileButton}
        iconPosition='right'
        disabled={isPerformingAction}
        data-testid='account-profile-delete-button'
        skin='tertiary'
        onClick={handleDeleteProfileClick}
      >
        {isDeleting && <Spinner size='tiny' accessibleText={t('Loading')} data-testid='account-profile-delete-button-spinner' />}
        {t('buttonDeleteProfile')}
      </Button>

      <ModalDialog isOpen={isDeleteProfileModalDialogOpen}>
        <ModalDialogContent aria-labelledby='modal dialog button'>
          <ModalDialogHeader>
            <ModalDialogTitle data-testid='cancel-modal-dialog-title'>{t('DeleteProfileModalDialogTitle')}</ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
            {t('DeleteProfileModalDialogDescription')}
          </ModalDialogBody>
          <ModalDialogFooter>
            <ModalDialogButtons
              className={styles.modalDialogButtons}
            >
              <Button
                className={styles.accountProfileButton}
                data-testid='delete-profile-modal-dialog-primary-button'
                skin='primary' onClick={handleDeleteProfileConfirmClick}
              >
                {t('DeleteProfileModalDialogPrimaryButton')}
              </Button>
              <Button
                className={styles.accountProfileButton}
                data-testid='delete-profile-modal-dialog-secondary-button'
                skin='secondary' onClick={handleDeleteProfileDiscardClick}
              >{t('DeleteProfileModalDialogSecondaryButton')}
              </Button>
            </ModalDialogButtons>
          </ModalDialogFooter>
        </ModalDialogContent>
      </ModalDialog>
    </FlexBox>
  )
}
